import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getCustomFields: (mastUuid, category) => {
		return axios.get(API_ENDPOINTS.GET_CUSTOM_FIELDS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastUuid } },
			params: { category: category },
		})
	},
	updateCustomFields: (body, mastUuid, fieldUuid) => {
		return axios.put(API_ENDPOINTS.UPDATE_CUSTOM_FIELDS, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { mastUuid, fieldUuid } },
		})
	},
}
