import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
import ButtonComp from '../../../sharedComponents/Button'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Grid, IconButton, FormControl, FormControlLabel, Radio, RadioGroup, InputAdornment, TextField, Tooltip, Typography } from '@mui/material'
import CopyrightIcon from '@mui/icons-material/Copyright'
import { useState } from 'react'
import clsx from 'clsx'
import * as yup from 'yup'
import { useFormik } from 'formik'
import BannerImg from './BannerImg'
import { decryption, getResponseMessage } from 'utils'
import { showMessage } from 'app/store/fuse/messageSlice'
import { useDispatch, useSelector } from 'react-redux'
import fileRecordDetail from 'services/patient/addRecord/files/api'
import { hideLoader, showLoader } from 'services/loader/action'
import countryLookup from 'services/register/api'
import _ from 'lodash'
import sendUploadFileApi from 'services/fileupload/PostApi'
import MicroSetupApi from 'services/MicroSetup/api'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded'
import Preview from './Preview'
import ProfileToolTip from 'app/sharedComponents/ProfileToolTip'
import profileStatusApi from 'services/ProfileSetup/profileStatus/api'
import fileUploadDetailsApi from 'services/fileupload/GetApi'
import { Close, Public } from '@mui/icons-material'
import CopyIcon from 'app/sharedComponents/CopyIcon'
import CompDropZone from 'app/sharedComponents/DropzoneUpload/Dropzone'

const useStyles = makeStyles((theme) => ({
	domainField: {
		display: 'flex',
		alignItems: 'center',
		gap: 8,
		'& .MuiTextField-root': {
			border: '1px solid #C4C4C4',
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-root': {
			// backgroundColor: '#fff',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
			'& :hover': {
				border: 'none',
			},
		},
		'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
			border: 'none',
			'&:hover': {
				border: 'none',
			},
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
			right: 24,
		},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-e82l64-MuiAutocomplete-root .MuiOutlinedInput-root': {
			paddingRight: 0,
		},
	},
	cancelBtn: {
		color: 'red',
		paddingLeft: 0,
		marginBottom: theme.spacing(1),
	},
	domainLabel: {
		'& .MuiTypography-h5': {
			paddingBottom: 8,
			color: '#000',
			fontSize: 13,
			fontWeight: 600,
		},
	},
	copyContent: {
		color: themesConfig.lyfngo.palette.tertiary.main,
		marginLeft: 2,
	},
	eligibleColor: {
		cursor: 'pointer',
		fontWeight: 500,
		color: (props) =>
			props?.availbleDomain === true ? '#0D9E00' : props?.availbleDomain === false ? 'red' : themesConfig.lyfngo.palette.primary.main,
		'& .MuiSvgIcon-root': {
			color: (props) =>
				props?.availbleDomain === true ? '#0D9E00' : props?.availbleDomain === false ? 'red' : themesConfig.lyfngo.palette.primary.main,
		},
	},
	themeCheck: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		// alignItems: 'center',
		'& .MuiTypography-h5': {
			color: '#000',
			fontSize: 13,
			fontWeight: 600,
		},
	},
	tick: {
		fontSize: 18,
		color: '#fff',
		transform: 'rotate3d(1, 1, 1, 23deg)',
	},
	colorOption: {
		width: '24px',
		height: '24px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		cursor: 'pointer',
		transition: 'background-color 0.2s',
		'&:hover': {
			transform: 'scale(1.1)',
		},
	},
	previewFavIcon: {
		background: '#ccecff',
		borderRadius: '16px 16px 0 0',
		padding: 6,
		marginBottom: 6,
		width: 180,
	},
	previewTitle: {
		maxWidth: 100,
		overflow: 'hidden',
		padding: '0 6px',
		textTransform: 'capitalize',
	},
	previewTitleBar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		background: '#fff',
		padding: '4px 8px',
		borderRadius: '16px 16px 0 0',
	},
	glowLabel: {
		color: '#000',
		position: 'relative',
		animation: 'glowing 2s infinite',
		fontSize: 13,
		fontWeight: 600,
	},
	'@keyframes glowing': {
		'0%': {
			textShadow: '0 0 5px #ffd700',
		},
		'50%': {
			textShadow: '0 0 15px #ffd700, 0 0 20px #ffcc00, 0 0 30px #ff9900',
			transform: 'scale(1.1)',
		},
		'100%': {
			textShadow: ' 0 0 5px #ffd700',
		},
	},
	aiHelper: {
		'& .MuiSvgIcon-root': {
			color: themesConfig.lyfngo.palette.primary.main,
		},
	},
	copyRights: {
		marginTop: 12,
		'& .MuiTypography-h5': {
			color: '#000',
			marginBlockEnd: 12,
			fontSize: 13,
			fontWeight: 600,
		},
		'& .MuiSvgIcon-root': {
			color: themesConfig.lyfngo.palette.primary.main,
		},
	},
	scriptGenerator: {
		marginBlock: 12,
		'& .MuiTypography-h5': {
			color: '#000',
			marginBlockEnd: 12,
			fontSize: 13,
			fontWeight: 600,
		},
		'& .MuiSvgIcon-root': {
			color: themesConfig.lyfngo.palette.primary.main,
		},
	},
	scriptHolder: {
		display: 'flex',
		justifyContent: 'space-between',
		height: 32,
	},
	enabledScript: {
		background: 'linear-gradient(to right, #80808021, #c0c0c0b5)',
		backdropFilter: 'blur(10px)',
		padding: 18,
		borderRadius: 12,
		whiteSpace: 'pre-wrap',
		overflow: 'auto',
	},
	fieldRights: {
		display: 'flex',
		gap: 12,
		'& .MuiSvgIcon-root': {
			color: '#000',
			fontSize: 18,
		},
		'& .MuiTextField-root': {
			border: '1px solid #C4C4C4',
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-root': {
			// backgroundColor: '#fff',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
			'& :hover': {
				border: 'none',
			},
		},
		'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
			border: 'none',
			'&:hover': {
				border: 'none',
			},
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
			right: 24,
		},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-e82l64-MuiAutocomplete-root .MuiOutlinedInput-root': {
			paddingRight: 0,
		},
	},
	mediaFields: {
		'& .MuiTypography-h5': {
			color: '#000',
			marginBlockEnd: 12,
			fontSize: 13,
			fontWeight: 600,
		},
		'& .MuiSvgIcon-root': {
			color: themesConfig.lyfngo.palette.primary.main,
		},
	},
	fieldStyle: {
		display: 'flex',
		gap: 12,
		paddingBlockEnd: 12,
		'& .MuiSvgIcon-root': {
			color: '#000',
			fontSize: 18,
		},
		'& .MuiTextField-root': {
			border: '1px solid #C4C4C4',
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-root': {
			// backgroundColor: '#fff',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
			'& :hover': {
				border: 'none',
			},
		},
		'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
			border: 'none',
			'&:hover': {
				border: 'none',
			},
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
			right: 24,
		},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-e82l64-MuiAutocomplete-root .MuiOutlinedInput-root': {
			paddingRight: 0,
		},
	},
	buttonMain: {
		display: 'flex',
		position: 'sticky',
		bottom: 0,
		padding: '30px 0',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'end',
		gap: 12,
	},
}))

const colors = [
	{ value: '#01B84A' },
	{ value: '#ff6601' },
	{ value: '#ffa800' },
	{ value: '#ffc700' },
	{ value: '#ff2e53' },
	{ value: '#03c194' },
	{ value: '#2eb2ff' },
	{ value: '#2e81ff' },
	{ value: '#bc2eff' },
	{ value: '#ff2ede' },
]

function MicroSetup({ moveNextTab, match }) {
	const dispatch = useDispatch()
	const action = _.get(match, 'params.action', '')
	const isViewMode = _.isEqual(action, 'view')
	const errorJson = useSelector((state) => state?.errorMessages)
	const state = useSelector((state) => state.Authentication)
	const { uuid, mastuuid } = state
	const { groupName } = useSelector((state) => state?.dynamicMenu?.userData || {})
	const isCare = _.isEqual(groupName, 'health care')
	const profileHeaderUuid = useSelector((state) => state?.profileSetup?.profileStatus?.profileHeaderUuid)
	const [profileStatus, setProfileStatus] = useState({})
	const groupuser = useSelector((state) => state?.GroupUser)
	const { groupUserName, tentUserId } = groupuser
	const [selectedColor, setSelectedColor] = useState(null)
	const [fileDetail, setFileDetail] = useState([])
	const [loading, setLoading] = useState(true)
	const [specialityDetails, SetspecialityDetails] = useState([])
	const countryCode = useSelector((state) => state?.dynamicMenu?.currentPractice?.tentCountryCode)
	const countryName = useSelector((state) => state?.dynamicMenu?.userData?.countryName)
	const [minMobileLen, setMinMobileLen] = useState(null)
	const [maxMobileLen, setMaxMobileLen] = useState(null)
	const [tentUserSearchValue, setTentUserSearchValue] = useState('')
	const [tentUserSearchParams, setTentUserSearchParams] = useState({
		userName: '',
	})
	const [specialityParams, setSpecialityParams] = useState('all')
	const [isEmptyCheck, SetIsEmptyCheck] = useState(null)
	const [availbleDomain, setAvailableDomain] = useState(null)
	const [domainEditBack, setDomainEditBack] = useState(null)
	const [enableDomainClose, setDomainClose] = useState(null)
	const [openPreviewModal, setOpenPreviewModal] = useState(false)
	const [establishmentFileResponse, setEstablishmentFileResponse] = useState([])
	const [fileCategoryList, setFileCategoryList] = useState([])
	const [favIconUuid, setFavIconUuid] = useState('')
	// console.log('favIconUuid', favIconUuid)
	const classes = useStyles({ availbleDomain })
	var envDomainName = process.env.REACT_APP_PROFILE_DOMAIN
	const [selectPreview, setSelectPreview] = useState('web')
	const onSearch = _.debounce(
		(value) => {
			setTentUserSearchParams({
				...tentUserSearchParams,
				userName: value,
			})
		},
		[300]
	)

	const onSubmit = (e) => {
		let body = {
			domainName: e?.email + envDomainName,
			themeColor: e?.colorCode,
			banner: {
				isBanner: e?.isBanner === 'yes' ? true : false,
				docDriveUuid: e?.isBanner === 'yes' ? _.map(establishmentFileResponse, (data, i) => data?.uuid || data) : [],
			},
			isBot: e?.isBot === 'yes' ? true : false,
			copyright: e?.copyright,
			media: {
				fb: e?.fb ? 'https://facebook.com/' + e?.fb : '',
				x: e?.twitter ? 'https://twitter.com/' + e?.twitter : '',
				insta: e?.insta ? 'https://instagram.com/' + e?.insta : '',
				thread: e?.thread ? 'https://threads.net/' + e?.thread : '',
				youtube: e?.youtube ? 'https://youtube.com/' + e?.youtube : '',
				linkedIn: e?.linkedin ? 'https://linkedin.com/' + e?.linkedin : '',
				whatsApp: e?.whatsapp ? e?.whatsapp : '',
			},
			isDomain: availbleDomain,
			favIconDocUuid: favIconUuid,
			googleReviewLink: !_.isEmpty(e?.reviewLink) ? e?.reviewLink : null,
			isScriptEnable: scriptEnable !== null ? true : false,
			isCustomer: _.isEqual(e?.isCustomer, 'true'),
		}
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				dispatch(hideLoader())
				setAvailableDomain(null)
				moveNextTab()
				// setAvailableDomain(res?.data?.data)
			}
		}
		const onFailure = () => {
			dispatch(hideLoader())
		}
		isEmptyCheck
			? MicroSetupApi.updateSiteSetup(body, mastuuid, uuid).then(onSuccess, onFailure)
			: MicroSetupApi.addSiteSetup(body, mastuuid, uuid).then(onSuccess, onFailure)
	}

	const formik = useFormik({
		initialValues: {
			email: '',
			copyright: '',
			fb: null,
			twitter: null,
			insta: null,
			thread: null,
			youtube: null,
			linkedin: null,
			colorCode: '',
			whatsapp: null,
			// bannerImg: [],
			isBanner: 'yes',
			isBot: 'no',
			reviewLink: null,
			isCustomer: false,
		},
		enableReinitialize: true,
		validationSchema: yup.object({
			email: yup
				.string()
				.required('Please enter the domain address')
				.min(2, 'Cannot contain less than 2 characters')
				.max(55, 'Cannot contain more than 55 characters')
				.matches(/^[^%#$!]+$/, 'Cannot contain (%#$!) special characters in the domain field'),
			isBanner: yup.string(),
			isAes: yup.string(),
			copyright: yup.string().nullable().required('Please enter the copyrights'),
			// .matches(/^Copyright (\d{4})-(\d{4}) (.+)$/, 'Invalid copyright format'),
			fb: yup
				.string()
				.nullable()
				.matches(/^[^%#$!]+$/, 'Cannot contain (%#$!) special characters in the facebook field'),
			twitter: yup
				.string()
				.nullable()
				.matches(/^[^%#$!]+$/, 'Cannot contain (%#$!) special characters in the X field'),
			insta: yup
				.string()
				.nullable()
				.matches(/^[^%#$!]+$/, 'Cannot contain (%#$!) special characters in the instagram field'),
			thread: yup
				.string()
				.nullable()
				.matches(/^[^%#$!]+$/, 'Cannot contain (%#$!) special characters in the thread field'),
			youtube: yup
				.string()
				.nullable()
				.matches(/^[^%#$!]+$/, 'Cannot contain (%#$!) special characters in the youtube field'),
			linkedin: yup
				.string()
				.nullable()
				.matches(/^[^%#$!]+$/, 'Cannot contain (%#$!) special characters in the Linkedin field'),
			colorCode: yup.string().nullable().required('Please choose any one theme'),
			whatsapp: yup
				.string()
				.nullable()
				.matches(/^([1-9][0-9]*)?$/, 'Enter valid number')
				.min(minMobileLen, `Mobile number should be minimum ${minMobileLen} digits`)
				.max(maxMobileLen, `Mobile number should be ${maxMobileLen} digit`),
		}),
		onSubmit: onSubmit,
	})

	// .com extraction
	const extractValueAfterDotCom = (url) => {
		if (typeof url === 'string') {
			const match = url.match(/(?:\.com|\.net)\/(.+)/)
			return match ? match[1] : ''
		}
	}

	const [previewData, setPreviewData] = useState({})

	const getScript = (data, match, isBot) => {
		if (_.isEqual(data, true) && match !== null) {
			let temp = `
			<div id='chatbot-container'></div>
			<script crossorigin src='https://unpkg.com/react@17/umd/react.production.min.js'></script>
			<script crossorigin src='https://unpkg.com/react-dom@17/umd/react-dom.production.min.js'></script>
			<script src='${process.env.REACT_APP_SCRIPT_BOT}'></script>
			<script>
			  function initChatbot() {
				window.renderChatbot('chatbot-container', '${match}${envDomainName}', ${isBot ? "'isBot'" : "'isAIBot'"});
			  }
			  window.onload = initChatbot;
			</script>
		`
			isBot ? setScriptEnable(temp) : setAiScriptEnable(temp)
		}
	}

	const getMicroSiteDta = () => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			let isBanner = res?.data?.data?.banner?.isBanner === true ? 'yes' : 'no'
			let isBot = res?.data?.data?.isBot === true ? 'yes' : 'no'
			setPreviewData(res?.data?.data)
			const colorCodeFromApi = res?.data?.data?.themeColor
			const domainNameParts = res?.data?.data?.domainName?.split('.')
			const match = domainNameParts?.length > 1 ? domainNameParts[0] : ''
			setDomainEditBack(match)
			getScript(res?.data?.data?.isScriptEnable, match, res?.data?.data?.isBot)
			setFavIconUuid(res?.data?.data?.favIconDocUuid)
			formik?.setFieldValue('email', match)
			formik?.setFieldValue('colorCode', colorCodeFromApi)
			setSelectedColor({ value: colorCodeFromApi })
			formik?.setFieldValue('copyright', res?.data?.data?.copyright)
			formik?.setFieldValue('fb', extractValueAfterDotCom(res?.data?.data?.media?.fb))
			formik?.setFieldValue('twitter', extractValueAfterDotCom(res?.data?.data?.media?.x))
			formik?.setFieldValue('insta', extractValueAfterDotCom(res?.data?.data?.media?.insta))
			formik?.setFieldValue('thread', extractValueAfterDotCom(res?.data?.data?.media?.thread))
			formik?.setFieldValue('youtube', extractValueAfterDotCom(res?.data?.data?.media?.youtube))
			formik?.setFieldValue('linkedin', extractValueAfterDotCom(res?.data?.data?.media?.linkedIn))
			// formik?.setFieldValue('bannerImg', res?.data?.data?.banner?.docDriveUuid)
			formik?.setFieldValue('whatsapp', res?.data?.data?.media?.whatsApp)
			formik?.setFieldValue('isBanner', isBanner)
			formik?.setFieldValue('isBot', isBot)
			formik?.setFieldValue('reviewLink', res?.data?.data?.googleReviewLink)
			formik?.setFieldValue('isCustomer', res?.data?.data?.isCustomer || false)
			setAvailableDomain(res?.data?.data?.isDomain)
			SetIsEmptyCheck(res?.data?.data?.isDomain)
			setEstablishmentFileResponse(res?.data?.data?.banner?.docDriveUuid || [])
			dispatch(hideLoader())
		}
		const onFailure = (err) => {
			setPreviewData({})
			dispatch(hideLoader())
		}
		MicroSetupApi.getSiteData(mastuuid, uuid).then(onSuccess, onFailure)
	}

	useEffect(() => {
		setLoading(true)
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			setProfileStatus(res?.data?.data || {})
			// _.isEqual(res?.data?.data?.progressStatus, 'IPG')
			// 	? history.push(`/profilestatus/view/${uuid}/${mastUuid}`)
			// 	: _.isEqual(res?.data?.data?.progressStatus, 'SUB') || _.isEqual(res?.data?.data?.progressStatus, 'RIP')
			// 	? history.push(`/profileunderverification/view/${uuid}/${mastUuid}`)
			// 	: _.isEqual(res?.data?.data?.progressStatus, 'APP')
			// 	? history.push(`/readytogolive/view/${uuid}/${mastUuid}`)
			// 	: _.isEqual(res?.data?.data?.progressStatus, 'LIV') && history.push(`/readytogolive/view/${uuid}/${mastUuid}`)
			// dispatch(setProfileStatus(res?.data?.data || {}))
			setLoading(false)
			dispatch(hideLoader())
		}
		const onFailure = (err) => {
			setProfileStatus({})
			dispatch(hideLoader())
		}
		let isNotOwner = !_.isEqual(groupUserName, 'owner')
		let isTent = !_.isEqual(groupUserName, 'owner') ? profileHeaderUuid : profileHeaderUuid
		profileStatusApi.getProfileStatus(isTent, mastuuid, isNotOwner).then(onSuccess, onFailure)
	}, [dispatch, groupUserName, mastuuid, profileHeaderUuid, uuid])

	const getFileCategoryList = useCallback(() => {
		dispatch(showLoader())
		const onSuccess = (res) => {
			if (res?.data?.status === 'SUCCESS') {
				setFileCategoryList(res?.data?.data)
			} else {
				setFileCategoryList([])
			}
			dispatch(hideLoader())
		}
		const onFailure = (err) => {
			console.log('Error', err)
			dispatch(hideLoader())
		}
		let isTent = !_.isEqual(groupUserName, 'owner') ? tentUserId : uuid
		fileUploadDetailsApi.getFileUploadDetails(isTent).then(onSuccess, onFailure)
	}, [groupUserName, tentUserId, uuid])

	useEffect(() => {
		getMicroSiteDta()
		getFileCategoryList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid])

	const deleteAllFiles = () => {
		setFileDetail([])
		setEstablishmentFileResponse([])
		let body = {
			fileUuids: _.map(fileDetail, (data) => data?.chartingFileUuid),
		}
		const onSuccess = () => {
			setEstablishmentFileResponse([])
		}
		!_.isEmpty(fileDetail) && fileRecordDetail.deleteAllfiles(body).then(onSuccess, () => {})
	}

	const displayEstablishmentImage = (acceptedFiles) => {
		dispatch(showLoader('Uploading image Please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'SUCCESS') {
				// let allFilesRes = _.uniqBy([...establishmentFileResponse, ...(res?.data?.data?.fileMetaDataList || [])], 'uuid')
				// let strictlySix = allFilesRes?.length > 6 ? allFilesRes.slice(0, 6) : allFilesRes
				// setEstablishmentFileResponse(strictlySix || [])
				const existingUUIDs = establishmentFileResponse.map((uuid) => uuid)
				const newUUIDs = res?.data?.data?.fileMetaDataList.map((file) => file.uuid)
				const allUUIDs = _.uniq([...existingUUIDs, ...newUUIDs])
				const latestSixUUIDs = allUUIDs.slice(Math.max(allUUIDs.length - 6, 0))
				setEstablishmentFileResponse(latestSixUUIDs)
			} else {
				setEstablishmentFileResponse([])
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let categoryUuid = 'ijwdsyh7'
		var formData = new FormData()
		_.map(acceptedFiles, (file) => formData.append('FILE', file))
		sendUploadFileApi.multiFileUpload(categoryUuid, mastuuid, uuid, formData).then(onSuccess, onFailure)
	}

	const handleColorClick = (color) => {
		setSelectedColor(color)
	}

	const domainCheck = () => {
		if (!_.isEmpty(formik?.values?.email)) {
			let body = {
				domain: formik?.values?.email + envDomainName,
			}
			dispatch(showLoader('Loading please wait...'))
			const onSuccess = (res) => {
				if (res?.data?.status === 'success') {
					dispatch(hideLoader())
					setAvailableDomain(res?.data?.data)
				}
			}
			const onFailure = (err) => {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(err?.response?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
			!_.isEmpty(formik?.values?.email) && MicroSetupApi.getDomainValidate(body, mastuuid, uuid).then(onSuccess, onFailure)
		}
	}

	const onPreviewModalClose = (event, reason) => {
		if (reason === 'backdropClick') {
			return null
		} else {
			setOpenPreviewModal(false)
		}
	}
	const onPreviewModalOpen = () => {
		setOpenPreviewModal(true)
	}

	const GetSpecialityDetails = () => {
		setLoading(true)
		const onSuccess = (res) => {
			setLoading(false)
			const successData = decryption(res)
			if (successData.status === 'success') {
				SetspecialityDetails(successData.data)
			}
		}
		const onFailure = (err) => {
			SetspecialityDetails([])
			setLoading(false)
		}
		MicroSetupApi.getSpecialityDetails(mastuuid).then(onSuccess, onFailure)
	}
	const [profileTentList, setProfileTentList] = useState([])
	const getProfileTentUserList = () => {
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData.status === 'success') {
				setProfileTentList(successData?.data?.tentUserServiceDTO)
			}
		}
		const onFailure = (err) => {
			setProfileTentList([])
		}
		MicroSetupApi.getProfileTentUserDetails(mastuuid, {
			getSpecialist: specialityParams || 'All Specialists',
			...tentUserSearchParams,
			getFacility: 'All Facilities',
		}).then(onSuccess, onFailure)
	}
	// useEffect(() => {
	// 	getProfileTentUserList()
	// }, [getProfileTentUserList])

	useEffect(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				let data = _.map(res?.data?.data, (item) => ({ ...item, mastLookupKey: `+${item?.mastLookupKey}` }))
				let code = _.orderBy(data, 'mastLookupKey', 'desc')
				let initCode = _.find(code, { country: countryName })
				setMinMobileLen(initCode?.minimumLength)
				setMaxMobileLen(initCode?.mastLookupValue)
			}
		}
		const onFailure = (err) => {
			console.log(err)
		}

		countryLookup.getCountryCode().then(onSuccess, onFailure)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleCopyContent = (previewData) => {
		dispatch(
			showMessage({
				message: 'Copied',
				autoHideDuration: 3000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
				variant: 'success',
			})
		)

		const urlToCopy = `https://${formik?.values?.email === null ? envDomainName : formik?.values?.email + envDomainName}`

		navigator.clipboard.writeText(urlToCopy)
	}

	const displayIdentityImage = (acceptedFiles) => {
		dispatch(showLoader('uploading image. Please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'SUCCESS') {
				setFavIconUuid(res?.data?.data?.uuid)
			} else {
				setFavIconUuid('')
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let identityCategoryId = fileCategoryList?.find((data) => data.categoryName === 'PROFILE_MODULE')
		let categoryUuid = identityCategoryId?.uuid
		var formData1 = new FormData()
		formData1.append('FILE', acceptedFiles[0])
		let isTent = !_.isEqual(groupUserName, 'owner') ? tentUserId : uuid
		sendUploadFileApi.sendUploadFile(mastuuid, isTent, categoryUuid, formData1).then(onSuccess, onFailure)
	}

	const [scriptEnable, setScriptEnable] = useState(null)
	const [aiScriptEnable, setAiScriptEnable] = useState(null)

	const generateAIFn = (data) => {
		let temp = `
        <div id='chatbot-container'></div>
        <script crossorigin src='https://unpkg.com/react@17/umd/react.production.min.js'></script>
        <script crossorigin src='https://unpkg.com/react-dom@17/umd/react-dom.production.min.js'></script>
        <script src='${process.env.REACT_APP_SCRIPT_BOT}'></script>
        <script>
          function initChatbot() {
            window.renderChatbot('chatbot-container', '${data}${envDomainName}', 'isAIBot');
          }
          window.onload = initChatbot;
        </script>
    `
		setAiScriptEnable(temp)
	}
	const generateFn = (data) => {
		let temp = `
        <div id='chatbot-container'></div>
        <script crossorigin src='https://unpkg.com/react@17/umd/react.production.min.js'></script>
        <script crossorigin src='https://unpkg.com/react-dom@17/umd/react-dom.production.min.js'></script>
        <script src='${process.env.REACT_APP_SCRIPT_BOT}'></script>
        <script>
          function initChatbot() {
            window.renderChatbot('chatbot-container', '${data}${envDomainName}', 'isBot');
          }
          window.onload = initChatbot;
        </script>
    `
		setScriptEnable(temp)
	}

	const handleCopyScript = (script) => {
		dispatch(
			showMessage({
				message: 'Copied script',
				autoHideDuration: 3000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
				variant: 'success',
			})
		)

		const urlToCopy = script
		navigator.clipboard.writeText(urlToCopy)
	}

	return (
		<>
			<Grid container xs={12} sx={{ padding: 2 }} spacing={2}>
				<Grid item xs={6}>
					<div className={classes.domainLabel}>
						<Typography variant='h5'>Sub Domain*</Typography>
						<div className={classes.domainField}>
							<TextField
								sx={{
									flex: '1',
								}}
								id='domain-size-small'
								size='small'
								fullWidth
								disabled={profileStatus?.progressStatus === 'LIV' || availbleDomain}
								value={formik?.values?.email}
								onChange={(e) => formik?.setFieldValue('email', e.target.value)}
								error={formik.touched.email && formik.errors.email}
								helperText={formik.touched.email && formik.errors.email}
							/>
							<Typography variant='body1'>{envDomainName}</Typography>
							{!loading && profileStatus?.progressStatus === 'LIV' ? (
								<Tooltip
									title='Once your domain name is live, updates cannot be made directly. Please contact LYFNGO Support for assistance in updating your domain information.'
									arrow
								>
									<HelpOutlineOutlinedIcon style={{ color: themesConfig.lyfngo.palette.primary.main }} />
								</Tooltip>
							) : (
								!loading && (
									<>
										{availbleDomain && (
											<Tooltip title='Edit' arrow>
												<IconButton
													onClick={() => {
														setAvailableDomain(null)
														setDomainClose(true)
													}}
												>
													<EditNoteRoundedIcon />
												</IconButton>
											</Tooltip>
										)}
										{domainEditBack && enableDomainClose && (
											<Tooltip title='Close' arrow>
												<IconButton
													onClick={() => {
														setAvailableDomain(true)
														setDomainClose(null)
													}}
												>
													<HighlightOffIcon style={{ color: 'red' }} />
												</IconButton>
											</Tooltip>
										)}
									</>
								)
							)}
							{!loading && profileStatus?.progressStatus !== 'LIV' && formik?.values?.email?.length >= 2 && (
								<Typography
									variant='body1'
									className={classes.eligibleColor}
									onClick={() => {
										if (availbleDomain === true) {
										} else if (availbleDomain === false) {
											domainCheck()
										} else {
											domainCheck()
										}
									}}
								>
									{!loading && availbleDomain === true
										? 'Domain Available'
										: availbleDomain === false && profileStatus?.progressStatus !== 'LIV'
										? 'Domain already taken'
										: !loading && 'Check eligible'}
									<ProfileToolTip
										titleName={`Check the availability of your dream domain instantly! Simply enter your desired domain name, and we'll swiftly assess its availability in real-time. Secure your online identity with ease and make sure your perfect domain is ready for the taking.`}
									>
										{availbleDomain === true ? (
											<CheckCircleRoundedIcon style={{ color: 'green' }} />
										) : availbleDomain === false ? (
											<HelpOutlineOutlinedIcon style={{ color: 'red' }} />
										) : (
											<HelpOutlineOutlinedIcon style={{ color: themesConfig.lyfngo.palette.primary.main }} />
										)}
									</ProfileToolTip>
								</Typography>
							)}
						</div>
						<Typography
							variant='h6'
							sx={{ color: '#787878', fontSize: '12px', paddingTop: 1, paddingBottom: 2, fontWeight: 400, display: 'flex', alignItems: 'center' }}
						>
							https://{'Ex: ' + formik?.values?.email === null ? envDomainName : formik?.values?.email + envDomainName}
							<Tooltip title='Copy' arrow>
								<CopyIcon onClick={handleCopyContent} />
							</Tooltip>
						</Typography>
					</div>
					<div className={classes.domainLabel} style={{ marginBottom: '16px' }}>
						<Typography variant='h5'>Current website</Typography>
						<div className={classes.domainField}>
							<TextField
								sx={{
									flex: '1',
								}}
								id='current-website-size-small'
								size='small'
								fullWidth
								value={`https://${formik?.values?.email === null ? envDomainName : formik?.values?.email + envDomainName}`}
							/>
						</div>
					</div>
					<div className={classes.themeForma}>
						<div className={classes.themeCheck}>
							<Typography variant='h5'>Select/Custom your theme color*</Typography>
							<div className={classes.domainField} style={{ position: 'relative', cursor: 'pointer' }}>
								<TextField
									type='text'
									size='small'
									value={formik.values.colorCode}
									onChange={(e) => {
										handleColorClick(e.target.value)
										formik?.setFieldValue('colorCode', e.target.value)
									}}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<div style={{ backgroundColor: formik.values.colorCode, width: '20px', height: '20px', borderRadius: '50%' }} />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position='end' sx={{ fontSize: '12px' }}>
												Hex
											</InputAdornment>
										),
									}}
								/>
								<TextField
									type='color'
									size='small'
									value={formik.values.colorCode}
									onChange={(e) => {
										handleColorClick(e.target.value)
										formik?.setFieldValue('colorCode', e.target.value)
									}}
									sx={{ width: 200, position: 'absolute', opacity: 0, cursor: 'pointer' }}
								/>
							</div>
							<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
								{colors.map((color) => (
									<div
										// key={color.value}
										className={clsx(classes.colorOption, { [classes.selected]: selectedColor?.value === color?.value })}
										style={{ backgroundColor: color?.value }}
										onClick={() => {
											handleColorClick(color)
											formik?.setFieldValue('colorCode', color?.value)
										}}
									>
										{selectedColor?.value === color?.value && <span className={classes.tick}>&#10003;</span>}
									</div>
								))}
							</div>
						</div>
						{formik.touched.colorCode && formik.errors.colorCode ? (
							<div style={{ marginTop: 4, color: '#F44336', fontSize: '11.5px' }}>{formik.errors.colorCode}</div>
						) : null}
					</div>
					{/* Upload FavIcon */}
					<div className='' style={{ display: 'flex', alignItems: 'flex-end', padding: '16px 0' }}>
						<CompDropZone
							imgResponse={favIconUuid}
							setImage={setFavIconUuid}
							imageTextOne='Drag Your Image'
							displayUploadImage={(files) => displayIdentityImage(files)}
							isType={'profile'}
						/>
						<div style={{ marginLeft: 16 }}>
							<div className={classes.previewFavIcon}>
								<div className={classes.previewTitleBar}>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<Public sx={{ color: '#aaa' }} />
										<Typography className={classes.previewTitle}>{formik?.values?.email}</Typography>
									</div>
									<Close sx={{ color: '#aaa', fontSize: 13 }} />
								</div>
							</div>
							<div className=''>
								<div style={{ display: 'flex', alignItems: 'end' }}>
									<Typography sx={{ fontWeight: 500 }}>Fav Icon*</Typography>
									<HelpOutlineOutlinedIcon sx={{ fontSize: 16, color: themesConfig.lyfngo.palette.tertiary.main }} />
								</div>
								<Typography sx={{ fontSize: 11, color: '#727272', margin: '3px 0' }}>
									JPG, PNG & JPEG <span style={{ color: '#9D9494' }}>Max 2MB/File</span>
								</Typography>
								<Typography sx={{ fontSize: 11, fontWeight: 500 }}>
									Suggested Size <span style={{ color: themesConfig.lyfngo.palette.primary.main, fontSize: 12 }}>1:1, 16:9</span>
								</Typography>
							</div>
						</div>
					</div>
					<BannerImg
						deleteAllFiles={deleteAllFiles}
						fileDetail={fileDetail}
						establishmentFileResponse={establishmentFileResponse}
						setEstablishmentFileResponse={setEstablishmentFileResponse}
						displayEstablishmentImage={displayEstablishmentImage}
						formik={formik}
					/>
				</Grid>
				<Grid item xs={6}>
					<div style={{ marginBlockEnd: '12px' }}>
						<Typography variant='h5' style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
							<span className={classes.glowLabel}>Enable ChatBot for Website?</span>
							<ProfileToolTip titleName={`You can opt for booking appointments and human agent chats!`}>
								<span className={classes.aiHelper}>
									<HelpOutlineOutlinedIcon />
								</span>
							</ProfileToolTip>
						</Typography>
						<FormControl>
							<RadioGroup
								row
								aria-labelledby='demo-radio-buttons-group-label'
								name='radio-buttons-group'
								value={formik?.values?.isBot}
								onChange={(event) => {
									formik?.setFieldValue('isBot', event.target.value)
								}}
							>
								<FormControlLabel value='yes' control={<Radio />} label='Chatbot' />
								<FormControlLabel value='no' control={<Radio />} label='AI Chatbot' />
							</RadioGroup>
						</FormControl>
					</div>
					{formik?.values?.isBot === 'yes' && (profileStatus?.progressStatus === 'LIV' || availbleDomain) && scriptEnable !== null ? (
						<div className={classes.scriptGenerator}>
							<div className={classes.scriptHolder}>
								<Typography variant='h5' sx={{ paddingBottom: '12px' }}>
									{`Copy this script and paste it into your website's.`}
								</Typography>
								<CopyIcon onClick={() => handleCopyScript(scriptEnable)} />
							</div>
							<div className={classes.enabledScript}>
								<pre style={{ marginLeft: '-64px' }}>{scriptEnable}</pre>
							</div>
						</div>
					) : (
						formik?.values?.isBot === 'yes' && (
							<div className={classes.scriptGenerator}>
								<Typography variant='h5'>
									{'Enable Script to use ChatBot in own Website'}{' '}
									<ProfileToolTip titleName={`Click the button to generate your script.`}>
										<span>
											<HelpOutlineOutlinedIcon />
										</span>
									</ProfileToolTip>
								</Typography>
								<ButtonComp onClick={() => generateFn(formik?.values?.email)}>Generate Script</ButtonComp>
							</div>
						)
					)}
					{formik?.values?.isBot === 'no' && (profileStatus?.progressStatus === 'LIV' || availbleDomain) && aiScriptEnable !== null ? (
						<div className={classes.scriptGenerator}>
							<div className={classes.scriptHolder}>
								<Typography variant='h5' sx={{ paddingBottom: '12px' }}>
									{`Copy this script and paste it into your website's.`}
								</Typography>
								<CopyIcon onClick={() => handleCopyScript(aiScriptEnable)} />
							</div>
							<div className={classes.enabledScript}>
								<pre style={{ marginLeft: '-64px' }}>{aiScriptEnable}</pre>
							</div>
						</div>
					) : (
						formik?.values?.isBot === 'no' && (
							<div className={classes.scriptGenerator}>
								<Typography variant='h5'>
									{'Enable Script to use ChatBot in own Website'}{' '}
									<ProfileToolTip titleName={`Click the button to generate your script.`}>
										<span>
											<HelpOutlineOutlinedIcon />
										</span>
									</ProfileToolTip>
								</Typography>
								<ButtonComp onClick={() => generateAIFn(formik?.values?.email)}>Generate Script</ButtonComp>
							</div>
						)
					)}

					<div className={classes?.domainLabel} style={{ marginBlockEnd: '12px' }}>
						<Typography variant='h5'>Google review link</Typography>
						<div className={classes?.domainField}>
							<TextField
								id='domain-size-small'
								size='small'
								fullWidth
								value={formik?.values?.reviewLink}
								onChange={(e) => formik?.setFieldValue('reviewLink', e.target.value)}
								onKeyDown={(e) => {
									// Prevent typing space character
									if (e.key === ' ') {
										e.preventDefault()
									}
								}}
								inputProps={{ maxLength: 500 }}
								// error={formik.touched.reviewLink && formik.errors.reviewLink}
								// helperText={formik.touched.reviewLink && formik.errors.reviewLink}
							/>
						</div>
					</div>
					<div style={{ marginBlockEnd: '12px' }}>
						<Typography variant='h5' style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
							<span className={classes.glowLabel}>Want to add new user as Lead or {isCare ? 'Patient' : 'Client'}</span>
							<ProfileToolTip titleName={`You can opt for booking appointments and human agent chats!`}>
								<span className={classes.aiHelper}>
									<HelpOutlineOutlinedIcon />
								</span>
							</ProfileToolTip>
						</Typography>
						<FormControl>
							<RadioGroup
								row
								aria-labelledby='demo-controlled-radio-buttons-group'
								name='controlled-radio-buttons-group'
								value={formik?.values?.isCustomer}
								onChange={(e) => formik?.setFieldValue('isCustomer', e.target.value)}
							>
								<FormControlLabel value={false} control={<Radio />} label='Lead' />
								<FormControlLabel value={true} control={<Radio />} label={isCare ? 'Patient' : 'Client'} />
							</RadioGroup>
						</FormControl>
					</div>
					<div className={classes.mediaFields}>
						<Typography variant='h5'>
							{'Social Media'}
							<ProfileToolTip
								titleName={`Social media integration enhances engagement and connectivity with your audience. Make it easy for visitors to connect with you across platforms!`}
							>
								<span>
									<HelpOutlineOutlinedIcon />
								</span>
							</ProfileToolTip>
						</Typography>
						<div className={classes.fieldStyle}>
							<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/facebook_icon.svg`} alt='F' />
							<TextField
								id='domain-size-small'
								size='small'
								fullWidth
								value={formik?.values?.fb}
								onChange={(e) => formik?.setFieldValue('fb', e.target.value)}
								onKeyDown={(e) => {
									// Prevent typing space character
									if (e.key === ' ') {
										e.preventDefault()
									}
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment
											position='start'
											sx={{
												'& .MuiSvgIcon-root': {
													color: '#000',
												},
											}}
										>
											<Typography variant='body1'>https://facebook.com/</Typography>
										</InputAdornment>
									),
								}}
								error={formik.touched.fb && formik.errors.fb}
								helperText={formik.touched.fb && formik.errors.fb}
							/>
						</div>
					</div>
					<div className={classes.mediaFields}>
						<div className={classes.fieldStyle}>
							<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/x_icon.svg`} alt='X' />
							<TextField
								id='domain-size-small'
								size='small'
								fullWidth
								value={formik?.values?.twitter}
								onChange={(e) => formik?.setFieldValue('twitter', e.target.value)}
								onKeyDown={(e) => {
									// Prevent typing space character
									if (e.key === ' ') {
										e.preventDefault()
									}
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment
											position='start'
											sx={{
												'& .MuiSvgIcon-root': {
													color: '#000',
												},
											}}
										>
											<Typography variant='body1'>https://twitter.com/</Typography>
										</InputAdornment>
									),
								}}
								error={formik.touched.twitter && formik.errors.twitter}
								helperText={formik.touched.twitter && formik.errors.twitter}
							/>
						</div>
					</div>
					<div className={classes.mediaFields}>
						<div className={classes.fieldStyle}>
							<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/instagram_icon.svg`} alt='I' />
							<TextField
								id='domain-size-small'
								size='small'
								fullWidth
								value={formik?.values?.insta}
								onChange={(e) => formik?.setFieldValue('insta', e.target.value)}
								onKeyDown={(e) => {
									// Prevent typing space character
									if (e.key === ' ') {
										e.preventDefault()
									}
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment
											position='start'
											sx={{
												'& .MuiSvgIcon-root': {
													color: '#000',
												},
											}}
										>
											<Typography variant='body1'>https://instagram.com/</Typography>
										</InputAdornment>
									),
								}}
								error={formik.touched.insta && formik.errors.insta}
								helperText={formik.touched.insta && formik.errors.insta}
							/>
						</div>
					</div>
					<div className={classes.mediaFields}>
						<div className={classes.fieldStyle}>
							<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/thread_icon.svg`} alt='T' />
							<TextField
								id='domain-size-small'
								size='small'
								fullWidth
								value={formik?.values?.thread}
								onChange={(e) => formik?.setFieldValue('thread', e.target.value)}
								onKeyDown={(e) => {
									// Prevent typing space character
									if (e.key === ' ') {
										e.preventDefault()
									}
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment
											position='start'
											sx={{
												'& .MuiSvgIcon-root': {
													color: '#000',
												},
											}}
										>
											<Typography variant='body1'>https://threads.net/</Typography>
										</InputAdornment>
									),
								}}
								error={formik.touched.thread && formik.errors.thread}
								helperText={formik.touched.thread && formik.errors.thread}
							/>
						</div>
					</div>
					<div className={classes.mediaFields}>
						<div className={classes.fieldStyle}>
							<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/youtube_icon.svg`} alt='Y' />
							<TextField
								id='domain-size-small'
								size='small'
								fullWidth
								value={formik?.values?.youtube}
								onChange={(e) => formik?.setFieldValue('youtube', e.target.value)}
								onKeyDown={(e) => {
									// Prevent typing space character
									if (e.key === ' ') {
										e.preventDefault()
									}
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment
											position='start'
											sx={{
												'& .MuiSvgIcon-root': {
													color: '#000',
												},
											}}
										>
											<Typography variant='body1'>https://youtube.com/</Typography>
										</InputAdornment>
									),
								}}
								error={formik.touched.youtube && formik.errors.youtube}
								helperText={formik.touched.youtube && formik.errors.youtube}
							/>
						</div>
					</div>
					<div className={classes.mediaFields}>
						<div className={classes.fieldStyle}>
							<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/linkedin_icon.svg`} alt='L' />
							<TextField
								id='domain-size-small'
								size='small'
								fullWidth
								value={formik?.values?.linkedin}
								onChange={(e) => formik?.setFieldValue('linkedin', e.target.value)}
								onKeyDown={(e) => {
									// Prevent typing space character
									if (e.key === ' ') {
										e.preventDefault()
									}
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment
											position='start'
											sx={{
												'& .MuiSvgIcon-root': {
													color: '#000',
												},
											}}
										>
											<Typography variant='body1'>https://linkedin.com/</Typography>
										</InputAdornment>
									),
								}}
								error={formik.touched.linkedin && formik.errors.linkedin}
								helperText={formik.touched.linkedin && formik.errors.linkedin}
							/>
						</div>
					</div>
					<div className={classes.mediaFields}>
						<div className={classes.fieldStyle}>
							<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/whatsapp_icon.svg`} alt='W' />
							<TextField
								id='domain-size-small'
								size='small'
								fullWidth
								value={formik?.values?.whatsapp}
								onChange={(e) => {
									const value = e.target.value
									if (/^\d*$/.test(value)) {
										formik?.setFieldValue('whatsapp', value)
									}
								}}
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{ maxLength: maxMobileLen, minLength: minMobileLen }}
								InputProps={{
									startAdornment: (
										<InputAdornment
											position='start'
											sx={{
												'& .MuiSvgIcon-root': {
													color: '#000',
												},
											}}
										>
											<Typography variant='body1'>{'WhatsApp Number:' + '  ' + countryCode}</Typography>
										</InputAdornment>
									),
								}}
								error={formik.touched.whatsapp && formik.errors.whatsapp}
								helperText={formik.touched.whatsapp && formik.errors.whatsapp}
							/>
						</div>
					</div>
					<div className={classes.copyRights}>
						<Typography variant='h5'>
							{'Copyright '}{' '}
							<ProfileToolTip
								titleName={`Copyright is a legal concept that provides the creators of original works with exclusive rights to use and distribute their work.`}
							>
								<span>
									<HelpOutlineOutlinedIcon />
								</span>
							</ProfileToolTip>
						</Typography>
						<div className={classes.fieldRights}>
							<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Profile%20Setup/Icons/copyrights_icons.svg`} alt='C' />
							<TextField
								id='domain-size-small'
								size='small'
								fullWidth
								value={formik?.values?.copyright}
								onChange={(e) => formik?.setFieldValue('copyright', e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment
											position='start'
											sx={{
												'& .MuiSvgIcon-root': {
													color: '#000',
												},
											}}
										>
											<CopyrightIcon /> <span style={{ color: '#000', fontWeight: '500', marginLeft: '4px' }}>{new Date().getFullYear()}</span>
										</InputAdornment>
									),
								}}
								error={formik.touched.copyright && formik.errors.copyright}
								helperText={formik.touched.copyright && formik.errors.copyright}
							/>
						</div>
					</div>
				</Grid>
			</Grid>
			{!isViewMode && (
				<div class={classes.buttonMain}>
					<ButtonComp style={{ visibility: 'hidden' }} onClick={() => moveNextTab()}>
						Back
					</ButtonComp>
					<ButtonComp
						onClick={() => {
							onPreviewModalOpen()
							GetSpecialityDetails()
							getProfileTentUserList()
						}}
					>
						Preview
					</ButtonComp>
					<ButtonComp
						disabled={availbleDomain !== true}
						onClick={(e) => {
							formik.handleSubmit(e)
						}}
					>
						Save & Next
					</ButtonComp>
				</div>
			)}
			<Preview
				openPreviewModal={openPreviewModal}
				onPreviewModalClose={onPreviewModalClose}
				previewData={previewData}
				formik={formik}
				specialityDetails={specialityDetails}
				profileTentList={profileTentList}
				establishmentFileResponse={establishmentFileResponse}
				selectPreview={selectPreview}
				setSelectPreview={setSelectPreview}
			/>
		</>
	)
}

export default MicroSetup
