import React, { useEffect, useState } from 'react'
import {
	Autocomplete,
	Avatar,
	Chip,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	Typography,
	createFilterOptions,
} from '@mui/material'
import CopyIcon from 'app/sharedComponents/CopyIcon'

import LinkIcon from '@mui/icons-material/Link'
// import Radio from '@mui/material/Radio'
// import RadioGroup from '@mui/material/RadioGroup'
// import FormControlLabel from '@mui/material/FormControlLabel'
import clsx from 'clsx'
import CustomDatePicker from 'app/sharedComponents/CustomDatePicker'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import CancelBtn from 'app/sharedComponents/CancelButton'
import _ from 'lodash'
import CustomTextFieldWithCountryCode from 'app/sharedComponents/CountryCodeAdornment'
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined'
import ApptDateRangePicker from './ApptDateRangePicker'
import { currencyCode } from 'utils'
import { useStyles } from './formStyles'
import ClientSearcher from './ClientSearcher'
import { showMessage } from 'app/store/fuse/messageSlice'
import { setApptPrepopulationData } from 'services/Appointments/action'

const NonCareAppointment = (props) => {
	const classes = useStyles()

	const {
		apptState,
		apptDispatch,
		firstFilterDate,
		setFirstFilterDate,
		nextDate,
		formik,
		countryCodeList,
		setCountryCodeLength,
		setCountryCodeMinLength,
		countryCode,
		setCountryCode,
		searchText,
		setSearchText,
		clientSearcher,
		getClientId,
		setGoogleIntegrateLink,
		clientState,
		clientDispatch,
		isEditAppt,
		preSelectedClient,
		//
		isCareFlow,
		performClientSearch,
		googleMeetlinkGeneration,
		googleIntegrateLink,
		setSelectTentUserId,
		timeSlotLoading,
		price,
		setPrice,
		setPriceValue,
	} = props
	const dateFormat = useSelector((state) => state?.dynamicMenu?.userData?.dateTimeFormat)
	const currencyType = useSelector((state) => state?.dynamicMenu?.userData?.currencySymbol || '')
	const countryTimezone = useSelector((state) => state?.dynamicMenu?.userData?.countryTimezone || '')
	const HomeServiceType = useSelector((state) => state?.dynamicMenu?.userData?.mastTentTypeName)

	const dispatch = useDispatch()
	const filter = createFilterOptions()
	const filterOptions = createFilterOptions({
		// matchFrom: 'start',
		stringify: (option) =>
			option.custName +
			option.custCustomId +
			option.custMobileNo +
			option.custEmail +
			option.custAge +
			option.custDob +
			option.custGender +
			option.postalCode +
			option.custBloodGroup +
			option.address1, // make it one for it
	})
	const handleCopyContent = () => {
		dispatch(
			showMessage({
				message: 'Copied',
				autoHideDuration: 3000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
				variant: 'success',
			})
		)
		navigator.clipboard.writeText(`${googleIntegrateLink}`)
	}
	useEffect(() => {
		const calculatePrice = () => {
			if (formik?.values?.spaceTaken && formik?.values?.timeSlot?.consultantionFees) {
				const newPrice = formik?.values?.spaceTaken * formik?.values?.timeSlot?.consultantionFees
				setPriceValue(formik?.values?.timeSlot?.consultantionFees)
				setPrice(`${currencyCode(newPrice, currencyType)}`)
			} else {
				setPrice('')
				setPriceValue('')
			}
		}

		calculatePrice()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currencyType, formik?.values?.spaceTaken, formik?.values?.timeSlot?.consultantionFees])

	return (
		<Grid container style={{ padding: 22, justifyContent: 'space-between' }} className={classes.root}>
			{/* <Grid item xs={5.75}>
				<Autocomplete
					id='patientSearch'
					disablePortal
					noOptionsText={'Search or Add Clients'}
					options={clientState?.clientsList}
					disabled={isEditAppt || !_.isEmpty(preSelectedClient)}
					getOptionLabel={(option) => {
						if (typeof option === 'string') {
							return option
						}
						if (option.inputValue) {
							return option.inputValue
						}
						return option.custName
					}}
					filterOptions={(options, params) => {
						const { inputValue } = params
						const filtered = filterOptions(options, params)
						if (inputValue.trim() !== '') {
							let format = /^[!@#$%^&*()_+\-=[\]{};':"\\|,.0-9<>/?]*$/
							if (!inputValue.match(format)) {
								filtered.push({
									inputValue,
									custName: `Add "${inputValue.replace(/[^A-Za-z ]/gi, '')}"`,
								})
							}
						}
						return filtered
					}}
					value={searchText}
					onChange={(event, value) => {
						if (value?.inputValue) {
							clientDispatch({
								type: 'setIsNewClient',
								data: true,
							})
							getClientId()
							formik?.setFieldValue('clientName', value?.inputValue)
						} else {
							clientDispatch({
								type: 'setIsNewClient',
								data: false,
							})
							formik?.setFieldValue('clientName', value)
							formik?.setFieldValue('clientId', value?.custCustomId)
							formik?.setFieldValue('custMobile', value?.custMobileNo)
							formik?.setFieldValue('custMobile', value?.custMobileNo)
							formik?.setFieldValue('clientEmail', value?.custEmail)
							// setting the  country code and countryCodeLength
							let code = _.find(countryCodeList, { mastLookupKey: value?.custCountryCode })
							setCountryCode(code)
							setCountryCodeLength(parseInt(code?.mastLookupValue))
							setCountryCodeMinLength(parseInt(code?.minimumLength))
						}
					}}
					onInputChange={(event, newInputValue) => {
						let filteredValue = newInputValue.replace(/[^A-Za-z0-9 ]/gi, '')
						setSearchText(filteredValue)
						clientSearcher(filteredValue)
					}}
					renderOption={(props, option) => (
						<li {...props}>
							<div className={classes.clients_list_item}>
								<Avatar
									alt='client'
									src={option?.custThumbNailPath || 'assets/images/icon/user-logo.png'}
									style={{ height: '34px', width: '34px', marginRight: 10 }}
								/>
								<div>
									<Typography>
										{option.custName} {option?.custCustomId ? `(${option?.custCustomId})` : ''}
									</Typography>
									<Typography>
										{option.custCountryCode} {option.custMobileNo}
									</Typography>
								</div>
							</div>
						</li>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							color='secondary'
							size='small'
							label='Search by name / Mobile number / Add client'
							placeholder='Example - John doe or 9876543210'
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{clientState?.clientsLoading ? <CircularProgress color='inherit' className={classes.circular_progress} size={20} /> : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
						/>
					)}
				/>
			</Grid> */}

			<Grid item xs={5.75}>
				<ClientSearcher
					clientState={clientState}
					clientDispatch={clientDispatch}
					performClientSearch={performClientSearch}
					getClientId={getClientId}
					formik={formik}
					isCareFlow={isCareFlow}
					countryCodeList={countryCodeList}
					setCountryCode={setCountryCode}
					setCountryCodeLength={setCountryCodeLength}
					setCountryCodeMinLength={setCountryCodeMinLength}
					preSelectedClient={preSelectedClient}
					isEditAppt={isEditAppt}
				/>
			</Grid>

			<Grid item xs={5.75} className={classes.mobileField}>
				<CustomTextFieldWithCountryCode
					id='custMobile'
					name='custMobile'
					size='small'
					type='tel'
					disabled={isEditAppt || !_.isEmpty(preSelectedClient) || !clientState?.isNewClient}
					value={formik?.values?.custMobile}
					onChange={(e) => {
						formik?.setFieldValue('custMobile', e.target.value)
					}}
					onBlur={formik?.handleBlur}
					label='Mobile number  *'
					color='secondary'
					variant='outlined'
					countryCodeValue={countryCode}
					countryCodeOnChange={(e, newValue) => {
						setCountryCode(newValue)
						setCountryCodeLength(parseInt(newValue?.mastLookupValue))
						setCountryCodeMinLength(parseInt(newValue?.minimumLength))
					}}
					disableCountryCode={isEditAppt || !_.isEmpty(preSelectedClient) || !clientState?.isNewClient}
					countryCodeList={countryCodeList}
					error={formik.touched.custMobile && formik.errors.custMobile}
					helperText={formik.touched.custMobile && formik.errors.custMobile && formik.errors.custMobile}
				/>
			</Grid>

			<Grid item xs={5.75}>
				{_.isEqual(clientState?.clientStatus, 'Customer') && (
					<Typography className={classes.patId} style={{ paddingTop: 12 }}>
						Client ID <span> {formik?.values?.clientId || ''}</span>
					</Typography>
				)}
			</Grid>
			<Grid item xs={5.75} className={clsx('mt-4', classes.normalTextFeild)}>
				<TextField
					id='clientEmail'
					label='E-mail'
					color='secondary'
					size='small'
					className='my-8'
					fullWidth
					value={formik?.values?.clientEmail}
					disabled={isEditAppt || !clientState?.isNewClient} // if the client is not new we are making non-editable
					onChange={(event) => formik?.setFieldValue('clientEmail', event.target.value)}
					error={formik.errors.clientEmail}
					helperText={formik.errors.clientEmail && formik.errors.clientEmail}
				/>
			</Grid>

			<Grid item xs={5.75}>
				<Autocomplete
					id='appointmentMode'
					name='appointmentMode'
					options={apptState?.appointmentModes}
					disabled={isEditAppt}
					disableClearable='true'
					autoHighlight
					size='small'
					getOptionLabel={(option) => option?.LabelTxt || ''}
					isOptionEqualToValue={(option, value) => option?.value === value?.value}
					value={formik?.values?.appointmentMode}
					onChange={(e, value) => {
						formik?.setFieldValue('appointmentMode', value)
						setGoogleIntegrateLink(null)
					}}
					noOptionsText={'No appointment modes found'}
					onBlur={formik.handleBlur}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Mode of appointment *'
							color='secondary'
							size='small'
							className='my-8'
							error={formik.touched.appointmentMode && formik.errors.appointmentMode}
							helperText={formik.touched.appointmentMode && formik.errors.appointmentMode}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={5.75}>
				<Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Grid item xs={9.8} className={clsx(classes.dateSelect)}>
						{apptState?.serial ? (
							<ApptDateRangePicker
								value={apptState?.pickerValue}
								setValue={(newDateRange) => {
									apptDispatch({
										type: 'setPickerValue',
										data: newDateRange,
									})
									if (!isEditAppt) {
										dispatch(
											setApptPrepopulationData({
												date: newDateRange,
											})
										)
									}
									formik?.setFieldValue('date', newDateRange.at(0))
								}}
							/>
						) : (
							<CustomDatePicker
								id='date-for-appointment'
								label='Select date for appointment *'
								value={formik?.values?.date}
								onAccept={(newdate) => {
									// dispatch(setAppointmentDate(null))
									formik?.setFieldValue('date', newdate)
									if (!isEditAppt) {
										dispatch(
											setApptPrepopulationData({
												date: newdate,
											})
										)
									}
								}}
								minDate={new Date(moment().format('YYYY-MM-DD'))}
								maxDate={new Date(moment().add(1, 'Y').format('YYYY-MM-DD'))}
							/>
						)}
					</Grid>
					<Grid item xs={2}>
						<Tooltip title={apptState?.serial ? 'Book appointment' : 'Book session'} arrow placement='top'>
							<IconButton
								disabled={isEditAppt}
								onClick={() => {
									apptDispatch({
										type: 'setSerial',
										data: !apptState?.serial,
									})
								}}
							>
								<SwapHorizontalCircleOutlinedIcon />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					{apptState?.serial && (
						<>
							<Stack direction='row' spacing={1} style={{ paddingBlock: 4 }}>
								{_.map(apptState?.pickerDaysSet, (item, i) => (
									<Chip
										key={i}
										label={item?.label}
										clickable
										variant={item?.isSelected ? 'filled' : 'outlined'}
										className={classes.chip}
										onClick={(e) => {
											const editedData = { id: item?.id, label: item?.label, isSelected: !item?.isSelected }
											const changeArr = apptState?.pickerDaysSet.map((u) => (u.id !== editedData.id ? u : editedData))
											_.map(apptState?.chipDays, (o) => {
												if (_.isEqual(o?.id, item?.id)) {
													//Actual state change
													let isSelectedReplace = { id: item?.id, label: item?.label, isSelected: !item?.isSelected }
													let removeOriginalArr = apptState?.pickerDaysSet.map((u) => (u.id !== isSelectedReplace.id ? u : isSelectedReplace))
													apptDispatch({
														type: 'setPickerDaysSet',
														data: removeOriginalArr,
													})

													//onClick data edit
													let removedData = _.filter(apptState?.chipDays, (u) => !_.isEqual(u?.id, item?.id))
													apptDispatch({
														type: 'setChipDays',
														data: _.uniqBy(removedData, 'id'),
													})
												} else if (!item?.isSelected === o?.isSelected && item?.id !== o?.id) {
													apptDispatch({
														type: 'setPickerDaysSet',
														data: changeArr,
													})
													apptDispatch({
														type: 'setChipDays',
														data: [...apptState?.chipDays, { id: item?.id, label: item?.label, isSelected: !item?.isSelected }],
													})
												}
											})
											if (_.isEmpty(apptState?.chipDays)) {
												apptDispatch({
													type: 'setPickerDaysSet',
													data: changeArr,
												})
												apptDispatch({
													type: 'setChipDays',
													data: [...apptState?.chipDays, { id: item?.id, label: item?.label, isSelected: !item?.isSelected }],
												})
											}
											!_.isEmpty(apptState?.chipDays) &&
												apptDispatch({
													type: 'setSerialErr',
													data: '',
												})
										}}
									/>
								))}
							</Stack>
							{!_.isEmpty(apptState?.serialErr) ? <Typography className='text-red-600 text-xs mx-8'>{apptState?.serialErr}</Typography> : ''}{' '}
						</>
					)}
				</Grid>
			</Grid>

			<Grid item xs={5.75}>
				<Autocomplete
					id='facility'
					name='facility'
					options={apptState?.facilityList || []}
					disabled={isEditAppt}
					disablePortal
					autoHighlight
					disableClearable
					filterSelectedOptions
					size='small'
					onBlur={formik.handleBlur}
					getOptionLabel={(option) => option?.specialityName || ''}
					isOptionEqualToValue={(option, value) => option?.specialityName === value?.specialityName}
					value={formik?.values?.facility ? formik?.values?.facility : null}
					onChange={(e, value) => {
						formik?.setFieldValue('facility', value)
						formik?.setFieldValue('paymentMandatory', value?.isPaymentMandatory)
					}}
					noOptionsText={'No facility found'}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Select facility *'
							color='secondary'
							size='small'
							className='my-8'
							error={formik.touched.facility && formik.errors.facility}
							helperText={formik.touched.facility && formik.errors.facility}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={5.75} style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Grid item xs={6.9}>
					<Autocomplete
						id='timeSlot'
						name='timeSlot'
						size='small'
						options={apptState?.timeSlotList}
						disableClearable
						filterSelectedOptions
						autoHighlight
						onBlur={formik.handleBlur}
						// getOptionLabel={(option) => option?.formattedTime || ''}
						getOptionLabel={(option) => {
							if (typeof option === 'string') {
								return option
							}
							if (option.inputValue) {
								return option.inputValue
							}
							return option?.formattedTime
						}}
						isOptionEqualToValue={(option, value) => option?.form === value?.formattedTime}
						value={formik?.values?.timeSlot}
						filterOptions={(options, params) => {
							const filtered = filter(options, params)
							const { inputValue } = params
							const isExisting = options.includes((option) => inputValue === option.formattedTime)
							if (inputValue.trim() !== '' && !isExisting) {
								filtered.push({
									inputValue,
									formattedTime: ` Add '${moment(inputValue, 'hh:mm A').format('hh:mm A')}'`,
									time: inputValue,
								})
							}
							return filtered
						}}
						onChange={(e, value) => {
							if (value?.inputValue) {
								setFirstFilterDate((prevState) => ({
									...prevState,
									formattedTime: moment(value?.inputValue, 'hh:mm A').format('hh:mm A'),
									time: value.inputValue,
								}))
								formik?.setFieldValue('timeSlot', {
									balanceSpace: firstFilterDate?.balanceSpace,
									consultantionDuration: firstFilterDate?.consultantionDuration,
									consultantionFees: firstFilterDate.consultantionFees,
									isPaymentMandatory: firstFilterDate?.isPaymentMandatory,
									formattedTime: moment(value?.inputValue, 'hh:mm A').format('hh:mm A'),
									time: value.inputValue,
								})
								formik?.setFieldValue('space', firstFilterDate?.balanceSpace)
							} else {
								formik?.setFieldValue('timeSlot', value)
								formik?.setFieldValue('space', value?.balanceSpace)
							}
						}}
						renderOption={(props, option) => (
							<li {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
								{option.formattedTime}
							</li>
						)}
						renderInput={(params) => (
							<TextField
								{...params}
								size='small'
								color='secondary'
								label={`Select time* ${countryTimezone}`}
								className='my-8'
								fullWidth
								variant='outlined'
								error={!timeSlotLoading && _.isEmpty(formik?.values?.timeSlot) && formik?.touched?.timeSlot && formik.errors.timeSlot}
								helperText={!timeSlotLoading && _.isEmpty(formik?.values?.timeSlot) && formik?.touched?.timeSlot && formik.errors.timeSlot}
							/>
						)}
					/>
				</Grid>
				{/* <Grid item xs={3.0} className={classes.normalTextFeild}>
					<TextField
						id='space'
						name='space'
						color='secondary'
						label='Space Taken'
						className='my-8'
						size='small'
						value={formik?.values?.spaceTaken}
						onChange={(e, value) => {
							formik?.setFieldValue('spaceTaken', Number(e?.target?.value))
						}}
						error={formik.touched.spaceTaken && formik.errors.spaceTaken}
						helperText={formik.touched.spaceTaken && formik.errors.spaceTaken}
					/>
				</Grid> */}
				<Grid item xs={4.0} className={classes.normalTextFeild}>
					<TextField
						id='space'
						name='space'
						color='secondary'
						label='Space'
						sx={{
							width: 90,
							'& .MuiOutlinedInput-root': {
								padding: 0,
							},
						}}
						className='my-8'
						size='small'
						disabled={true}
						value={formik?.values?.timeSlot && formik?.values?.timeSlot?.balanceSpace ? formik?.values?.timeSlot?.balanceSpace : ''}
						error={formik.touched.spaceTaken && formik.errors.spaceTaken}
						helperText={formik.touched.spaceTaken && formik.errors.spaceTaken}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<TextField
										id='space'
										name='space'
										color='secondary'
										className='my-8'
										size='small'
										sx={{
											width: 40,
											'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
												borderColor: '#fff',
												'& :hover': {
													borderColor: '#fff',
												},
											},
										}}
										value={formik?.values?.spaceTaken}
										onChange={(e, value) => {
											formik?.setFieldValue('spaceTaken', Number(e?.target?.value))
										}}
									/>
									<span>/</span>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Grid>

			<Grid item xs={5.75}>
				<Autocomplete
					id='specialistName'
					name='specialistName'
					options={apptState?.specialistList}
					disabled={isEditAppt}
					getOptionLabel={(option) => `${option?.tentUserSalutation ? option?.tentUserSalutation + '.' : ''} ${option?.tentUserFirstName}` || ''}
					isOptionEqualToValue={(option, value) => option?.tentUserFirstName === value?.tentUserFirstName}
					value={formik?.values?.specialistName}
					onChange={(e, value) => {
						formik?.setFieldValue('specialistName', value)
						setSelectTentUserId(value?.tentUserUuid)
						// formik?.setFieldValue('date', moment(new Date()).format('YYYY-MM-DD'))
					}}
					onBlur={formik.handleBlur}
					noOptionsText={!formik?.values?.appointmentMode ? 'Please select a appointment mode' : 'No Specialists found'}
					renderInput={(params) => (
						<TextField
							{...params}
							label={HomeServiceType === 'Home Service' ? 'Specialist name *' : 'Specialist name'}
							color='secondary'
							size='small'
							className='my-8'
							error={formik.touched.specialistName && formik.errors.specialistName}
							helperText={formik.touched.specialistName && formik.errors.specialistName}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={5.75} style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Grid item xs={5.75} className={classes.normalTextFeild}>
					<TextField
						id='duration'
						color='secondary'
						className='my-8'
						size='small'
						label='Duration'
						disabled={true}
						value={
							formik?.values?.timeSlot && formik?.values?.timeSlot?.consultantionDuration
								? `${formik?.values?.timeSlot?.consultantionDuration} Mins`
								: ''
						}
					/>
				</Grid>
				<Grid item xs={5.75} className={classes.normalTextFeild}>
					<TextField id='price' color='secondary' className='my-8' size='small' label='Price' disabled={true} value={price} />
				</Grid>
			</Grid>

			<Grid item xs={5.75}>
				{!apptState?.serial && (
					<Typography className={clsx(classes.patId, 'mb-8 mt-8')}>
						Payment mandatory
						<ToggleButtonGroup
							className={classes.togglebtngrp}
							exclusive
							disabled={isEditAppt}
							value={formik?.values?.paymentMandatory}
							onChange={(e, value) => {
								formik?.setFieldValue('paymentMandatory', value)
							}}
						>
							<ToggleButton value={true} disabled={formik?.values?.paymentMandatory}>
								Yes
							</ToggleButton>
							<ToggleButton value={false} disabled={!formik?.values?.paymentMandatory}>
								No
							</ToggleButton>
						</ToggleButtonGroup>
					</Typography>
				)}
			</Grid>
			<Grid item xs={5.75} className={classes.normalTextFeild}>
				<TextField
					id='Notes'
					label='Notes'
					color='secondary'
					className='mt-10'
					disabled={isEditAppt}
					size='small'
					fullWidth
					multiline
					minRows={3}
					value={formik?.values?.notes}
					onChange={(event) => formik?.setFieldValue('notes', event.target.value)}
					error={formik.errors.notes}
					helperText={formik.errors.notes && formik.errors.notes}
				/>
			</Grid>
			{formik?.values?.appointmentMode?.LabelTxt === 'Online' && (
				<Grid item xs={12} className={classes.normalTextFeild} display={'flex'} justifyContent={'right'} mt={1}>
					<Grid item xs={5.75} className={classes?.meetingLinkContainer}>
						<Grid item xs={9.8}>
							<TextField
								id='meetinglink'
								color='secondary'
								fullWidth
								className='my-8'
								size='small'
								InputLabelProps={{
									shrink: true,
								}}
								label='Meeting Link'
								disabled={true}
								value={googleIntegrateLink}
							/>
						</Grid>
						{googleIntegrateLink && (
							<Grid item xs={1}>
								<CopyIcon
									onClick={() => {
										handleCopyContent()
									}}
								/>
							</Grid>
						)}
						<Grid item xs={1}>
							<Tooltip title={'Meeting Link'} arrow placement='top'>
								<IconButton
									disabled={!_.isEmpty(googleIntegrateLink) || _.isEqual(formik?.values?.custMobile, '')}
									onClick={() => {
										googleMeetlinkGeneration()
									}}
								>
									<LinkIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	)
}

export default NonCareAppointment
